import './ContentCreatorsPage.css';
import React, { FC } from 'react';

const ContentCreatorsPage: FC = () => <>
    <div className='contentCreatorsMainDiv'>
        <h2 className='creators-header'>Content Creators</h2>
        <div className='contentCreator' id='lineageBreak'>
            <p className='contentCreatorName'>Lineage Break</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UULFVfOMFfdrjR50pEnLsVrNeA' title='Latest LineageBreak video' allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id='maindeck'>
            <p className='contentCreatorName'>Maindeck</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed/videoseries?si=9aCwQXuVbgs5H57B&amp;list=PLulixabBEemsvfL3d5275Gl8X4uew9CNO' title='Latest Maindeck video' allowFullScreen></iframe>
        </div>
		<div className='contentCreator' id="Raravin">
            <p className='contentCreatorName'>Raravin</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UUtLbnQsVL4Zw5UpTgDVkLZQ' title="Latest Raravin video" allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id='tm32'>
            <p className='contentCreatorName'>TM32</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UULF82Nu1M_YE6ju9dihgI8ieg' title='Latest TM32 video' allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id='talkga'>
            <p className='contentCreatorName'>TalkGA</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UUT2Qizj1z9Vw7yul0WwXTYg' title='Latest TalkGA video' allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id='trueChampionGaming'>
            <p className='contentCreatorName'>True Champion Gaming</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UULFHGnCeBHoOdcsdb1X0Oi9qg' title='Latest True Champion Gaming video' allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id='Banner Knight Stew'>
            <p className='contentCreatorName'>Banner Knight Stew</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UUTvJYgZ995jlPX5QA2igoOg' title='Latest Duo Leveling video' allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id='valkyrieLoaf'>
            <p className='contentCreatorName'>Valkyrie Loaf</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UULFbeUkp8rjl4r4fJs6Gna5Pg' title='Latest Valkyrie Loaf video' allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id='The Magic Library TCG'>
            <p className='contentCreatorName'>The Magic Library TCG</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UUggRPfkjTZ9dLjchNn12U2A' title='Latest The Magic Library TCG video' allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id='redZoneRogue'>
            <p className='contentCreatorName'>Red Zone Rogue</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UULFSl5xtiqtMuSFmO9Gs866ug' title='Latest Red Zone Rogue video' allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id='creativeShock'>
            <p className='contentCreatorName'>Creative Shock</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UULF9XCeZbyVmWWXaLc2o-KLjg' title='Latest Creative Shock video' allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id="LeviTCG">
            <p className='contentCreatorName'>LeviTCG</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UULFmcpT6hkpAo31ztXnfljwUQ' title="Latest LeviTCG video" allowFullScreen></iframe>
        </div>
		<div className='contentCreator' id="Chess Club">
            <p className='contentCreatorName'>Chess Club</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UUyPPFPv-dBacHZO9kZp2aFg' title="Latest Chess Club video" allowFullScreen></iframe>
        </div>
		<div className='contentCreator' id="Biefall">
            <p className='contentCreatorName'>Biefall</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UUJzaZ3MSOXHxpjSUOF0Pocw' title="Latest Biefall video" allowFullScreen></iframe>
        </div>
		<div className='contentCreator' id="SolarGames">
            <p className='contentCreatorName'>SolarGames</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UUMZ0zLuKtKJ6nXTmP___HTw' title="Latest SolarGames video" allowFullScreen></iframe>
        </div>
		<div className='contentCreator' id="Tomoya Ch.">
            <p className='contentCreatorName'>Tomoya Ch.</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UUrJWs9LeZ0LcnHfRxi8OKPQ' title="Latest Tomoya Ch. video" allowFullScreen></iframe>
        </div>
		<div className='contentCreator' id="TT Plays.">
            <p className='contentCreatorName'>TT Plays</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UUUvIrAXik6nGSLzoBrLfoqA' title="Latest TT Plays video" allowFullScreen></iframe>
        </div>
        <div className='contentCreator' id='duoLeveling'>
            <p className='contentCreatorName'>Duo Leveling</p>
            <iframe className='videoArea' src='https://www.youtube.com/embed?listType=playlist&list=UULF9AyD8AoWSKovWQWYsNzL4g' title='Latest Duo Leveling video' allowFullScreen></iframe>
        </div>
    </div>
</>

export default ContentCreatorsPage;